import { darken } from '../../_snowpack/pkg/polished.js';
export const colors = {
  defrost: '#f47f98',
  error: '#EF4836',
  frost: '#f3f4f6',
  frostbite: '#4c505b',
  glacier: '#8fdecb',
  glacierDarker: darken(0.3, '#8fdecb'),
  glacierLighter: '#CEEAE7',
  green: '#daf0ed',
  grey: '#ddd',
  light: '#dfdfdf',
  lightGrey: '#FAFAFA',
  lightText: 'rgba(0,0,0,0.7)',
  roboto: "'Roboto', sans-serif",
  varela: "'Varela', sans-serif",
  yellow: '#ffe6a5',
  greyBorderColor: '#F7F7F7',
  label: '#9095A8',
  text: '#4C4F5A'
};